import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from '../favicon.png';
// import logo from '../assets/img/image.png';
// import logo from '../assets/img/cube.png';
import navIcon1 from '../assets/img/github.svg';
import navIcon2 from '../assets/img/linkedin.svg';
import navIcon3 from '../assets/img/resume.svg';
import Resume from '../assets/Resume.pdf';
// import Resume from '/Resume.pdf';
import { HashLink } from 'react-router-hash-link';
import {
  BrowserRouter as Router
} from "react-router-dom";

export const NavBar = () => {

  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

  const updateDefaultActiveKey = () => {
    const tabContainer = document.getElementById('projects-tabs');
    if (tabContainer) {
      tabContainer.dataset.defaultActiveKey = 'second';
    } else {
      console.error('not found');
    }
  }

  const updateActiveTab = () => {
    const projectSection = document.getElementById('projects');
    if (projectSection) {
      const tabContainer = projectSection.querySelector('#projects-tabs');
      if (tabContainer) {
        tabContainer.dataset.defaultActiveKey = 'second';
      } else {
        console.error('Tab container with id "projects-tabs" not found.');
      }
    } else {
      console.error('Element with id "projects" not found.');
    }
  }

  function buttonCliker(string) {
    let button = document.querySelector(`button[id=${string}]`);
    button.click();
  }

  return (
    <Router>
      <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
              {/* <Nav.Link href="#skills" className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('skills')}>Skills</Nav.Link> */}
              <Nav.Link href="#projects" className={activeLink === 'projects' ? 'active navbar-link' : 'navbar-link'} onClick={() => {
                onUpdateActiveLink('projects')
                buttonCliker('projects-one')
              }}>Projects</Nav.Link>

              <Nav.Link href="#live-applications" className={activeLink === 'projects' ? 'active navbar-link' : 'navbar-link'} onClick={() => buttonCliker('live-applications')}>Demo</Nav.Link>

              <Nav.Link href="#blog" className={activeLink === 'blog' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('projects')}>Blog</Nav.Link>
            </Nav>
            <span className="navbar-text">
              <div className="social-icon">
                <a href="https://github.com/13illydakid" target="_blank" rel="noopener noreferrer"><img src={navIcon1} alt="" /></a>
                <a href="https://www.linkedin.com/in/billy-h-chiu" target="_blank" rel="noopener noreferrer"><img src={navIcon2} alt="" /></a>
                <a href={Resume} target="_blank" rel="noopener noreferrer"><img src={navIcon3} alt="" /></a>
              </div>
              <HashLink to='#personal-bios'>
                <button className="vvd"><span>Get to Know Me.</span></button>
              </HashLink>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Router>
  )
}
